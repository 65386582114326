<template>
  <div :style="{ overflow: isOpenMenu ? 'hidden' : 'visible' }">
    <LeftMenu
      v-if="this.$auth.user().password_upd"
      :isMenuMinimize="isMenuMinimize"
      :isOpenMenu="isOpenMenu"
      @toggleMenuSize="onMenuSizeToggle"
    />

    <main
      :class="[
        'main',
        { 'main--menu-min': isMenuMinimize },
        { 'main--menu-open': isOpenMenu },
      ]"
    >
      <TopMenu
        :isOpenMenu="isOpenMenu"
        @toggleMenu="$store.commit('leftMenu/setMenuOpen', $event)"
      />
      <router-view />
    </main>

    <portal-target name="pwa-tips" />
  </div>
</template>

<script>
import TopMenu from "@/navigation/TopMenu";
import LeftMenu from "@/navigation/LeftMenu";
import { mapState } from "vuex";

export default {
  name: "defaultLayout",

  components: { LeftMenu, TopMenu },

  watch: {
    $route() {
      this.$store.commit("leftMenu/setMenuOpen", false);
    },
  },

  methods: {
    onMenuSizeToggle() {
      this.$store.dispatch("leftMenu/toggleMenuSize");
    },
  },

  computed: {
    ...mapState({
      isMenuMinimize: (state) => state.leftMenu.isMenuMinimize,
      isOpenMenu: (state) => state.leftMenu.isOpenMenu,
    }),
  },

  created() {
    this.$store.dispatch("leftMenu/init");
  },
};
</script>
